// @mui
import { frFR } from '@mui/material/locale';
import FranceFlag from '../assets/icons/flags/ic_flag_fr.svg';
// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------
export const allLangs = [
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: FranceFlag,
    },
];
export const defaultLang = allLangs[0]; // English
